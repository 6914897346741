import React from 'react'
import { graphql, Link } from 'gatsby'
import { Container, Box } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '../_theme'

const BlogPage = ({ data, ...props }) => {
  const { allBlockContent, allMdx } = data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const posts = allMdx?.nodes || []

  // Sort posts by date manually since we can't do it in the query
  const sortedPosts = [...posts].sort((a, b) => {
    return new Date(b.frontmatter.date) - new Date(a.frontmatter.date)
  })

  return (
    <Layout theme={theme} {...props}>
      <Seo
        title="Feature Flag Management Blog - Toggly"
        description="Learn about feature flag best practices, release management, and how to ship features faster and safer."
        keywords={["feature flags", "feature toggles", "continuous deployment", "blog"]}
        author="opsAI LLC"
        category="Software Development"
        thumbnail="https://toggly.io/cover.png"
        siteUrl="https://toggly.io"
        locale="en_US"
      />

      {/* Blocks */}
      <Header content={content['header-light']} menuJustify='space-between' />
      <Divider space='5' />

      <Container>
        <Box sx={{ textAlign: 'center', mb: 5 }}>
          <h1>Feature Flag Management Blog</h1>
          <p>Learn about feature flag best practices, release management, and how to ship features faster and safer.</p>
        </Box>
        
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: 4 }}>
          {sortedPosts.map((post, index) => {
            // Safely generate slug from contentFilePath or fallback to title
            let slug = ''
            if (post.internal?.contentFilePath) {
              const parts = post.internal.contentFilePath.split('content/posts/')
              if (parts.length > 1) {
                slug = parts[1].replace(/\.mdx$/, '')
              }
            }
            if (!slug) {
              slug = post.frontmatter.slug
            }
            // Fallback to slugified title if no valid path
            if (!slug) {
              slug = post.frontmatter.title
                .toLowerCase()
                .replace(/[^a-z0-9]+/g, '-')
                .replace(/(^-|-$)/g, '')
            }

            return (
              <Box 
                key={post.id} 
                sx={{
                  bg: 'white',
                  borderRadius: 'lg',
                  overflow: 'hidden',
                  boxShadow: 'default',
                  transition: 'all 0.2s ease-in-out',
                  ':hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 'hover'
                  }
                }}
              >
                <Box sx={{ p: 4 }}>
                  <Box sx={{ mb: 3 }}>
                    <span sx={{ 
                      bg: 'alphaLighter',
                      color: 'alpha',
                      px: 3,
                      py: 1,
                      borderRadius: 'full',
                      fontSize: 1
                    }}>
                      {post.frontmatter.category}
                    </span>
                  </Box>
                  <h3 sx={{ mb: 3, fontSize: 3 }}>
                    <Link 
                      to={`/blog/${slug}`}
                      sx={{ 
                        color: 'omegaDarker', 
                        textDecoration: 'none',
                        ':hover': {
                          color: 'alpha'
                        }
                      }}
                    >
                      {post.frontmatter.title}
                    </Link>
                  </h3>
                  <p sx={{ mb: 3, color: 'omega' }}>{post.excerpt}</p>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    pt: 3,
                    borderTop: '1px solid',
                    borderColor: 'omegaLighter'
                  }}>
                    <Box sx={{ flex: 1 }}>
                      <span sx={{ color: 'omegaDark', fontWeight: 'medium' }}>
                        {post.frontmatter.author}
                      </span>
                    </Box>
                    <Box>
                      <span sx={{ color: 'omega' }}>
                        {new Date(post.frontmatter.date).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Container>

      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query blogPageContent {
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
    allMdx {
      nodes {
        id
        excerpt
        internal {
          contentFilePath
        }
        frontmatter {
          title
          slug
          category
          author
          date
          tags
        }
      }
    }
  }
`

export default BlogPage